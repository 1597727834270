<template>
  <q-header
    :class="$q.dark.isActive ? 'bg-dark' : 'bg-white text-black'"
    bordered
  >
    <q-toolbar>
      <div class="row justify-between items-center full-width">
        <div class="col flex items-center col-shrink">
          <q-btn
            aria-label="Menu"
            class="hamburger lt-sm"
            data-cy="hamburger"
            dense
            flat
            icon="mdi-menu"
            round
            @click="emit('click:toggle-drawer')"
          />
        </div>

        <div class="flex col row justify-center">
          <div class="col q-mx-md" style="max-width: 700px" />
        </div>
        <div class="col flex justify-end items-center col-grow cursor-pointer">
          <q-btn
            v-if="!isLoggedIn"
            :class="[
              'icon--grey loginButton',
              $q.screen.lt.md ? 'q-px-xs' : '',
            ]"
            flat
            icon="mdi-login-variant"
            :label="$t('user.sign_in')"
            :href="getLoginUrl()"
          />
          <LayoutAvatar v-if="isLoggedIn" has-menu />
        </div>
      </div>
    </q-toolbar>
  </q-header>
</template>

<script lang="ts" setup>
import useAuth from "~/composables/useAuth";
import { LayoutAvatar } from "#components";
import { useQuasar } from "quasar";

const emit = defineEmits(["click:toggle-drawer"]);
const { isLoggedIn, getLoginUrl } = await useAuth();

const $q = useQuasar();
</script>

<style lang="scss" scoped>
hr {
  margin-block-end: 0;
  margin: 0;
}
</style>
