<template>
  <q-drawer
    :model-value="true"
    :width="85"
    bordered
    persistent
    :breakpoint="-1"
    :mini="false"
    class="max-height: 100vh"
    data-cy="sidebar"
    :show-if-above="true"
    style="overflow: hidden"
  >
    <div class="column full-height justify-between">
      <div>
        <NuxtLink to="/" class="column justify-center" style="height: 50px">
          <q-img
            :src="`/images/brand/haz_h${$q.dark.isActive ? '_dark' : ''}.svg`"
            alt="hazcheckLogo"
            data-cy="secondaryLogo"
            eager
            fit="contain"
            height="25px"
            width="100%"
          />
        </NuxtLink>
        <q-separator />
        <LayoutRail />
      </div>
      <div class="column full-width q-pt-md">
        <div class="column full-width items-center gap-md q-pb-md">
          <div v-if="config.public.version" class="full-width text-center">
            <q-separator class="full-width" />
            <div class="text-subtitle2" style="font-size: 12px">
              {{ config.public.version }}
            </div>
            <q-separator class="full-width" />
          </div>
          <q-separator v-else class="full-width" />
          <Logo variant="wsc" fit="contain" height="35px" class="q-px-sm" />
          <Logo variant="ncb-square" height="40px" fit="contain" />
        </div>
      </div>
    </div>
  </q-drawer>
</template>
<script lang="ts" setup>
import { useQuasar } from "quasar";
import { NuxtLink, LayoutRail, Logo } from "#components";
import { useRuntimeConfig } from "#imports";

defineProps<{
  leftDrawerOpen: boolean;
}>();

const config = useRuntimeConfig();

const $q = useQuasar();

defineEmits(["click:toggle-drawer"]);
</script>
<style lang="scss" scoped>
hr {
  margin-block-end: 0;
  margin: 0;
}
</style>
