<template>
  <q-list class="full-height grow column items-stretch">
    <LayoutRailLink
      v-for="link in sideBarLinks"
      :key="link.title"
      :link="link"
    />
    <LayoutRailLink
      v-if="extraLinks.length"
      :link="{
        icon: 'mdi-dots-horizontal',
        title: 'More',
        callback: () => {},
      }"
    >
      <q-menu anchor="center right" self="center left">
        <Card class="flex gap-sm no-wrap q-pa-sm">
          <LayoutRailLink
            v-for="link in extraLinks"
            :key="link.title"
            :link="link"
            style="width: 84px"
          />
        </Card>
      </q-menu>
    </LayoutRailLink>
  </q-list>
</template>

<script lang="ts" setup>
import { useRailLinks } from "~/composables/useRailLinks";

const { sideBarLinks, extraLinks } = await useRailLinks();
</script>
